import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from 'next/link';

import PageRibbon from '@pegase/ui/tools/page-ribbon';
import Spacer from '@pegase/ui/views/spacer';

import { LocaleProvider } from '~/shared/core/locale-provider';
import Footer from '~/shared/views/footer';

interface Props {
  onRetry: () => void;
}

export const InternalError = (props: Props) => {
  return (
    <LocaleProvider locale="en">
      <Stack minHeight="100vh">
        <PageRibbon>
          <Box sx={{ mt: 4, mb: 4 }}>
            <Container maxWidth="lg" disableGutters>
              <Grid container direction="column" justifyContent="space-between" alignItems="center">
                <Typography variant="h3" gutterBottom>
                  Oops!
                </Typography>
                <Typography variant="h6" align="center" gutterBottom>
                  Well, you broke the internet!
                </Typography>
                <Box sx={{ mt: 3, mb: 3 }}>
                  <Typography variant="body1" align="center" gutterBottom>
                    Just kidding, looks like we have an internal issue, please try again in couple minutes
                  </Typography>
                </Box>
                <Grid container justifyContent="center">
                  <Button component={Link} href="/" variant="outlined" color="secondary" startIcon={<ArrowBackIcon />}>
                    Home
                  </Button>
                  <Spacer height={0} width={1} />
                  <Button variant="contained" color="secondary" onClick={props.onRetry}>
                    Try again
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </PageRibbon>
        <Footer />
      </Stack>
    </LocaleProvider>
  );
};
