import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from 'next/link';

import PageRibbon from '@pegase/ui/tools/page-ribbon';

import { LocaleProvider } from '~/shared/core/locale-provider';
import Footer from '~/shared/views/footer';

export const PageNotFound = () => {
  return (
    <LocaleProvider locale="en">
      <Stack minHeight="100vh">
        <PageRibbon>
          <Box sx={{ mt: 4, mb: 4 }}>
            <Container maxWidth="lg" disableGutters>
              <Grid container direction="column" justifyContent="space-between" alignItems="center">
                <Typography variant="h3" gutterBottom>
                  Oops!
                </Typography>
                <Typography variant="h6" align="center" gutterBottom>
                  Page not found
                </Typography>
                <Box sx={{ mt: 3, mb: 3 }}>
                  <Typography variant="body1" align="center" gutterBottom>
                    Sorry but we could not find the page you are looking for
                  </Typography>
                </Box>
                <Button component={Link} href="/" variant="contained" color="secondary" startIcon={<HomeRoundedIcon />}>
                  Go to Home
                </Button>
              </Grid>
            </Container>
          </Box>
        </PageRibbon>

        <Footer />
      </Stack>
    </LocaleProvider>
  );
};
