import type * as React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import type { SxProps, Theme } from '@mui/material/styles';

interface Props {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}

export default function PageRibbon(props: Props) {
  return (
    <Stack component="main" sx={{ flexGrow: 1 }}>
      <Box
        sx={{
          height: { xs: 0, xl: 150 },
          backgroundColor: 'primary.dark',
        }}
      />
      <Box
        sx={{
          marginTop: { xs: 0, xl: '-100px' },
          boxSizing: 'border-box',
          margin: 0,
          pb: { xs: 0, xl: 3 },
          flex: { xs: 1, xl: 0 },
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}>
        <Container disableGutters maxWidth="xl" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <Paper
            elevation={0}
            sx={{
              p: 2,
              borderRadius: { xs: 0, xl: 2 },
              boxShadow: 1,
              flexGrow: 1, // Ensures the Paper component takes up the available space
              display: 'flex',
              backgroundImage: 'none',
              flexDirection: 'column',
              ...props.sx,
            }}>
            {props.children}
          </Paper>
        </Container>
      </Box>
    </Stack>
  );
}
