'use client';

import { InternalError } from '~/shared/views/internal-error';
import { PageNotFound } from '~/shared/views/page-not-found';

interface Props {
  reset: () => void;
  error: {
    message: string;
  };
}

const MyError = (props: Props) => {
  switch (props.error.message) {
    case 'NEXT_NOT_FOUND':
      return <PageNotFound />;

    default:
      return <InternalError onRetry={props.reset} />;
  }
};

export default MyError;
